import { Component, OnInit } from '@angular/core';
import { ConectorService } from '../servicios/conector-service';

@Component({
  selector: 'app-cabecera',
  templateUrl: './cabecera.component.html',
  styleUrls: ['./cabecera.component.css']
})
export class CabeceraComponent implements OnInit {
  public nombreparacabecera: string;

  constructor(
    private  conector: ConectorService, // lo utilizo para poner nombre y apellido en cabecera cuando se loguee
  ) { }

  ngOnInit() {
    this.conector.customMessage.subscribe(msg => this.nombreparacabecera = msg);
  }
}
