import { Component, OnInit } from '@angular/core';
import { Categorias } from '../modelos/categorias';
import { CategoriasService } from "../servicios/categorias.service";
import { Tiposiconograficos } from '../modelos/tiposiconograficos';
import { TiposiconograficosService } from "../servicios/tiposiconograficos.service";
import { Router } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-crearcategoria',
  templateUrl: './crearcategoria.component.html',
  styleUrls: ['./crearcategoria.component.css'],
  providers: [CategoriasService, TiposiconograficosService]
})
export class CrearcategoriaComponent implements OnInit {
  public categoria: Categorias;
  public tiposiconograficos: Tiposiconograficos;
  public titulo: string;
  public ruta: string;
  public ficheroselecionado: string;
  public filesToUpload: File[];
  public resultUpload: any;
  public respuesta: number; // lo usamos para recibir los errores del servidor
  public mensajerespuesta: string; //lo usamos para presentar el mensaje retornado

  constructor(
    private _categoriasService: CategoriasService,
    private _tiposiconograficosService: TiposiconograficosService,
    private router: Router
  ) { 
    this.categoria = new Categorias(null, null, "", null , null, "", "", "", "");
    this.titulo = "Crear Categoría";
    this.ruta=GLOBAL.urlImagenes;
    this.respuesta=0; // inicializamos a 0 como respuesta correcta
    this.mensajerespuesta=null; // inicializamos vacio como respuesta correcta
  }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
    this.cargartipos();
  }

  onSubmit() {
    //Para hacer la subida del fichero
    console.log('submit ok');

    if(this.filesToUpload && this.filesToUpload.length >= 1){
      this._categoriasService.makeFileRequest(GLOBAL.url+'upload-file', [], this.filesToUpload).then((result)=>{
          console.log(result);
          this.resultUpload=result;
          this.categoria.urlImagen = this.resultUpload.filename;
          this.guardamoscategoria();
        }, (error) => {
          console.log(error);
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
        });
      } else {
        this.guardamoscategoria();
      }
    }

    cargartipos(){
      console.log("Cargando Tipos");
      this._tiposiconograficosService.getTiposiconograficosselect().subscribe(
        result => {
            if(result.code != 200){
                console.log(result);
            }else{
                this.tiposiconograficos = result.data;
                console.log(result);
            }
        },
        error => {
            console.log(<any>error);
        }
      );
    }

    guardamoscategoria(){
    this._categoriasService.addCategoria(this.categoria).subscribe(
      result => {
        if(result.code == 200){
          console.log(result);
          this.respuesta=result.code; // cargamos el codigo de respuesta recibido
          this.router.navigate(['/listarcategorias']);
        }else{
          this.respuesta=result.code; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta=result.message; // cargamos el mensaje recibido
          console.log(result);
        }
      },
      error => {
          console.log(<any>error);
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
      }
    );
  }

  fileChangeEvent(fileInput: any){
    this.filesToUpload = <Array<File>>fileInput.target.files;
    // Mueve el nombre del fichero al imput file del html, sin esto no se ve
    this.categoria.urlImagen = fileInput.target.files.item(0).name;
		console.log(this.filesToUpload);
  }

}
