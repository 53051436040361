import { Component, OnInit } from '@angular/core';
import { Ubicaciones } from '../modelos/ubicaciones';
import { UbicacionesService } from "../servicios/ubicaciones.service";
import { Router, Params, ActivatedRoute } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-modificarubicacion',
  templateUrl: './modificarubicacion.component.html',
  styleUrls: ['./modificarubicacion.component.css'],
  providers: [UbicacionesService]
})
export class ModificarubicacionComponent implements OnInit {
  public ubicacion: Ubicaciones;
  public titulo: string;
  public ruta: string;
  public filesToUpload: File[];
  public resultUpload: any;
  public respuesta: number; // lo usamos para recibir los errores del servidor
  public mensajerespuesta: string; //lo usamos para presentar el mensaje retornado

  constructor(
    private _ubicacionesService: UbicacionesService,
    private route: ActivatedRoute,
    private router: Router
  ) { 
    this.ruta=GLOBAL.urlImagenes;
    this.ubicacion = new Ubicaciones(null, "", null , null, "", "", "", "");
    this.titulo = "Crear Ubicación";
    this.respuesta=0; // inicializamos a 0 como respuesta correcta
    this.mensajerespuesta=null; // inicializamos vacio como respuesta correcta
  }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
    this.leerUbicacion();
  }

  ngAfterViewInit() {
    // va al top de la página cuando se entra
    window.scrollTo(0, 0);
  }

  onSubmit() {
    //console.log(this.posicion);

    if(this.filesToUpload && this.filesToUpload.length >= 1){
			this._ubicacionesService.makeFileRequest(GLOBAL.url+'upload-file', [], this.filesToUpload).then((result) => {
				console.log(result);

				this.resultUpload = result;
				this.ubicacion.urlImagen = this.resultUpload.filename;
				this.updateUbicacion();

			}, (error) =>{
				console.log(error);
			});
		}else{
			this.updateUbicacion();	
		}
  }

  leerUbicacion() {
    this.route.params.forEach((params: Params) => {
      let id=params['id'];

      this._ubicacionesService.getUbicacion(id).subscribe(
        result => {
            if(result.code == 200){
              console.log(result);
              this.ubicacion = result.data;
            }else{
              alert("Error al leer registro");
              console.log(result);
            }
        },
        error => {
            console.log(<any>error);
        }
      );
    });
  }

  updateUbicacion() {
    console.log("entro en update");
    console.log(this.ubicacion);
    
    this.route.params.forEach((params: Params) => {
      let id=params['id'];
      console.log(id);
      this._ubicacionesService.updateUbicacion(id, this.ubicacion).subscribe(

        result => {
          console.log(result);
          if(result.code != 200){
            this.respuesta=result.code; // cargamos el codigo de respuesta recibido
            this.mensajerespuesta=result.message; // cargamos el mensaje recibido
            //console.log(result);
          }else{
            //console.log(result);
            this.respuesta=result.code; // cargamos el codigo de respuesta recibido
            this.router.navigate(['/listarubicaciones']);
          }
        },
        error => {
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
          console.log(<any>error);
        }
      );
    });
  }

  fileChangeEvent(fileInput: any){
    this.filesToUpload = <Array<File>>fileInput.target.files;
    // Mueve el nombre del fichero al imput file del html, sin esto no se ve
    this.ubicacion.urlImagen = fileInput.target.files.item(0).name;
		console.log(this.filesToUpload);
  }

}
