import { Component, OnInit } from '@angular/core';
import { Situaciones } from '../modelos/situaciones';
import { SituacionesService } from "../servicios/situaciones.service";
import { Posiciones } from '../modelos/posiciones';
import { PosicionesService } from "../servicios/posiciones.service";
import { Router } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-crearsituacion',
  templateUrl: './crearsituacion.component.html',
  styleUrls: ['./crearsituacion.component.css'],
  providers: [SituacionesService, PosicionesService]
})
export class CrearsituacionComponent implements OnInit {
  public situacion: Situaciones;
  public posiciones: Posiciones;
  public titulo: string;
  public ruta: string;
  public ficheroselecionado: string;
  public filesToUpload: File[];
  public resultUpload: any;
  public respuesta: number; // lo usamos para recibir los errores del servidor
  public mensajerespuesta: string; //lo usamos para presentar el mensaje retornado

  constructor(
    private _situacionesService: SituacionesService,
    private _posicionesService: PosicionesService,
    private router: Router
  ) { 
    this.situacion = new Situaciones(null, null, "", null , null, "", "", "", "");
    this.titulo = "Crear Situación";
    this.ruta=GLOBAL.urlImagenes;
    this.respuesta=0; // inicializamos a 0 como respuesta correcta
    this.mensajerespuesta=null; // inicializamos vacio como respuesta correcta
  }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
    this.cargarposiciones();
  }

  onSubmit() {
    //Para hacer la subida del fichero
    console.log('submit ok');

    if(this.filesToUpload && this.filesToUpload.length >= 1){
      this._situacionesService.makeFileRequest(GLOBAL.url+'upload-file', [], this.filesToUpload).then((result)=>{
          console.log(result);
          this.resultUpload=result;
          this.situacion.urlImagen = this.resultUpload.filename;
          this.guardamossituacion();
        }, (error) => {
          console.log(error);
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
        });
      } else {
        this.guardamossituacion();
      }
    }

    cargarposiciones(){
      console.log("Cargando Posiciones");
      this._posicionesService.getPosicionesselect().subscribe(
        result => {
            if(result.code != 200){
                console.log(result);
            }else{
                this.posiciones = result.data;
                console.log(result);
            }
        },
        error => {
            console.log(<any>error);
        }
      );
    }

    guardamossituacion(){
    this._situacionesService.addSituacion(this.situacion).subscribe(
      result => {
        if(result.code == 200){
          console.log(result);
          this.respuesta=result.code; // cargamos el codigo de respuesta recibido
          this.router.navigate(['/listarsituaciones']);
        }else{
          this.respuesta=result.code; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta=result.message; // cargamos el mensaje recibido
          console.log(result);
        }
      },
      error => {
          console.log(<any>error);
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
      }
    );
  }

  fileChangeEvent(fileInput: any){
    this.filesToUpload = <Array<File>>fileInput.target.files;
    // Mueve el nombre del fichero al imput file del html, sin esto no se ve
    this.situacion.urlImagen = fileInput.target.files.item(0).name;
		console.log(this.filesToUpload);
  }

}
