import { Component, OnInit } from '@angular/core';
import { Usuarios } from '../modelos/usuarios';
import { UsuariosService } from "../servicios/usuarios.service";
import { Router, Params, ActivatedRoute } from "@angular/router";
import {GLOBAL} from "../servicios/global";
import { ConectorService } from '../servicios/conector-service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [UsuariosService]
})
export class HomeComponent implements OnInit {
  public titulo: string;
  public usuario: Usuarios;
  public respuesta: number; // lo usamos para recibir los errores del servidor
  public mensajerespuesta: string; //lo usamos para presentar el mensaje retornado
  public validado: string; // lo usamos para saber si está o no validado un usuario
  public nombreusuario: string;
  public apellidosusuario: string;
  public nombreparacabecera: string;

  constructor(
    private  conector: ConectorService, // lo utilizo para poner nombre y apellido en cabecera cuando se loguee
    private _usuariosService: UsuariosService,
    private route: ActivatedRoute,
    private router: Router
  ) { 
    this.titulo = "Administración BD Grafitos Mayas";
    this.usuario = new Usuarios(null, "", "", "", "", "" ,  null, null, "", "", "");
    this.respuesta=0; // inicializamos a 0 como respuesta correcta
    this.mensajerespuesta=null; // inicializamos vacio como respuesta correcta
    this.validado=null;
  }

  ngOnInit() {
    this.validado=localStorage.getItem('autorizado');
    this.nombreusuario=localStorage.getItem('nombre');
    this.apellidosusuario=localStorage.getItem('apellidos');
    this.nombreparacabecera= this.nombreusuario + " " + this.apellidosusuario;
    this.conector.customMessage.subscribe(msg => this.nombreparacabecera = msg);
  }

  onSubmit() {
    console.log("entro en submit");
    console.log(this.usuario.email);
    console.log(this.usuario.password);

    this.route.params.forEach((params: Params) => {
      this._usuariosService.validoUsuario(this.usuario.email, this.usuario.password).subscribe(
        result => {
          console.log(result);
          if(result.code != 200){
            this.respuesta=result.code; // cargamos el codigo de respuesta recibido
            this.mensajerespuesta=result.message; // cargamos el mensaje recibido
            console.log(result);
          }else{
            console.log(result);
            localStorage.setItem('autorizado', '1'); // guardamos en storage 1 para validar el acceso
            localStorage.setItem('id', result.data.idUsuario); // guardamos en storage el nombre para sacarlo luego
            localStorage.setItem('nombre', result.data.nombre); // guardamos en storage el nombre para sacarlo luego
            localStorage.setItem('apellidos', result.data.apellidos); // guardamos en storage el apellido para sacarlo luego
            this.nombreparacabecera = result.data.nombre + " " + result.data.apellidos;
            this.conector.changeMessage(this.nombreparacabecera); // cargamos el valor en el servicio para que cambie en la cabecera
            this.respuesta=result.code; // cargamos el codigo de respuesta recibido
            this.mensajerespuesta=' SE HA LOGADO CORRECTAMENTE '; // cargamos el mensaje recibido
            // this.router.navigate(['/listarusuarios']);
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    });
  }

  logout() {
    localStorage.clear();
    this.validado='0';
  }
}
