import { Component, OnInit } from '@angular/core';
import { Dibujos } from '../modelos/dibujos';
import { DibujosService } from "../servicios/dibujos.service";
import { Grafitos } from '../modelos/grafitos';
import { GrafitosService } from "../servicios/grafitos.service";
import { Router } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-creardibujo',
  templateUrl: './creardibujo.component.html',
  styleUrls: ['./creardibujo.component.css'],
  providers: [DibujosService, GrafitosService]
})
export class CreardibujoComponent implements OnInit {
  public dibujo: Dibujos;
  public grafitos: Grafitos;
  public titulo: string;
  public ruta: string;
  public ficheroselecionado: string;
  public filesToUpload: File[];
  public resultUpload: any;
  public respuesta: number; // lo usamos para recibir los errores del servidor
  public mensajerespuesta: string; //lo usamos para presentar el mensaje retornado


  constructor(
    private _dibujosService: DibujosService,
    private _grafitosService: GrafitosService,
    private router: Router
  ) { 
    this.dibujo = new Dibujos(null, null, "", "", "", "", null, "" , null, null, "", "");
    this.titulo = "Crear Dibujos";
    this.ruta=GLOBAL.urlImagenes;
    this.respuesta=0; // inicializamos a 0 como respuesta correcta
    this.mensajerespuesta=null; // inicializamos vacio como respuesta correcta
  }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
    this.cargagrafitos();
  }

  onSubmit() {
    //Para hacer la subida del fichero
    console.log('submit ok');

    if(this.filesToUpload && this.filesToUpload.length >= 1){
      this._dibujosService.makeFileRequest(GLOBAL.url+'upload-file', [], this.filesToUpload).then((result)=>{
          console.log(result);
          this.resultUpload=result;
          this.dibujo.urlImagen = this.resultUpload.filename;
          this.guardamosdibujo();
        }, (error) => {
          console.log(error);
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
        });
      } else {
        this.guardamosdibujo();
      }
    }

    cargagrafitos(){
      console.log("Cargando Grafitos");
      this._grafitosService.getGrafitosSelect().subscribe(
        result => {
            if(result.code != 200){
                console.log(result);
            }else{
                this.grafitos = result.data;
                console.log(result);
            }
        },
        error => {
            console.log(<any>error);
        }
      );
    }

    guardamosdibujo(){
    this._dibujosService.addDibujo(this.dibujo).subscribe(
      result => {
        if(result.code == 200){
          console.log(result);
          this.respuesta=result.code; // cargamos el codigo de respuesta recibido
          this.router.navigate(['/listardibujos']);
        }else{
          this.respuesta=result.code; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta=result.message; // cargamos el mensaje recibido
          console.log(result);
        }
      },
      error => {
          console.log(<any>error);
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
      }
    );
  }

  fileChangeEvent(fileInput: any){
    this.filesToUpload = <Array<File>>fileInput.target.files;
    // Mueve el nombre del fichero al imput file del html, sin esto no se ve
    this.dibujo.urlImagen = fileInput.target.files.item(0).name;
		console.log(this.filesToUpload);
  }

}
