export class Proyectos {
    constructor(
      public idProyecto: number,
      public descripcion: string,
      public direccion: string,
      public webproyecto: string,
      public usuAlta: number,
      public usuMod: number,
      public created_at: string,
      public updated_at: string,
      public glosarioDescripcion: string,
      public urlImagen: string
    ) {}
  }