import { Component, OnInit } from '@angular/core';
import { Usuarios } from '../modelos/usuarios';
import { UsuariosService } from "../servicios/usuarios.service";
import { Router  } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-crearusuario',
  templateUrl: './crearusuario.component.html',
  styleUrls: ['./crearusuario.component.css'],
  providers: [UsuariosService]
})
export class CrearusuarioComponent implements OnInit {
  public usuario: Usuarios;
  public titulo: string;
  public filesToUpload: File[];
  public resultUpload: any;
  public respuesta: number; // lo usamos para recibir los errores del servidor
  public mensajerespuesta: string; //lo usamos para presentar el mensaje retornado

  constructor(
    private _usuariosService: UsuariosService,
    private router: Router
  ) { 
    this.usuario = new Usuarios(null, "", "", "", "", "" , null, null, "", "", "");
    this.titulo = "Crear Usuario";
    this.respuesta=0; // inicializamos a 0 como respuesta correcta
    this.mensajerespuesta=null; // inicializamos vacio como respuesta correcta
  }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }

  }

  ngAfterViewInit() {
    // va al top de la página cuando se entra
    window.scrollTo(0, 0);
  }

  onSubmit() {
    //Para hacer la subida del fichero

    if(this.filesToUpload && this.filesToUpload.length >= 1){
      this._usuariosService.makeFileRequest(GLOBAL.url+'upload-file', [], this.filesToUpload).then((result)=>{
          console.log(result);
          this.resultUpload=result;
          this.usuario.urlImagen = this.resultUpload.filename;
          this.guardamosUsuario();
        }, (error) => {
          console.log(error);
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
        });
      } else {
        this.guardamosUsuario();
      }
    }

  guardamosUsuario(){
    this._usuariosService.addUsuario(this.usuario).subscribe(
      result => {
        if(result.code == 200){
          console.log(result);
          this.respuesta=result.code; // cargamos el codigo de respuesta recibido
          this.router.navigate(['/listarusuarios']);
        }else{
          console.log(result);
          this.respuesta=result.code; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta=result.message; // cargamos el mensaje recibido
        }
      },
      error => {
          console.log(<any>error);
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
      }
    );
  }

  fileChangeEvent(fileInput: any){
		this.filesToUpload = <Array<File>>fileInput.target.files;
		console.log(this.filesToUpload);
  }

}
