import { Component, OnInit, ViewChild } from '@angular/core';
import { Bibliografias } from "../modelos/bibliografias";
import { BibliografiasService } from "../servicios/bibliografias.service";
import { Router } from "@angular/router";
import {GLOBAL} from "../servicios/global";
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';


@Component({
  selector: 'app-listarbibliografias',
  templateUrl: './listarbibliografias.component.html',
  styleUrls: ['./listarbibliografias.component.css'],
  providers: [BibliografiasService]
})
export class ListarbibliografiasComponent implements OnInit {
  public bibliografia: Bibliografias;
  public confirmado:number;
  public titulo: string;

  displayedColumns = ['nombre', 'idBibliografia', 'referencia', 'modificar', 'borrar'];
  dataSource: any;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  

  constructor(
    private router: Router,
    private dataService: BibliografiasService,
  ) { 
  }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
    
    
    this.listarbibliografias();
    
    this.confirmado=null;
    this.titulo="Listado de Bibliografias";
    
  }

  ngAfterViewInit() {
    // va al top de la página cuando se entra
    window.scrollTo(0, 0);
  }

  // Saca el listado de todos los registros
  listarbibliografias(){

    /* carga el servicio para datatable de angular material */
    this.dataService.getBibliografias().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.dataSource = new MatTableDataSource();  
              this.dataSource.data = result.data; 
              this.dataSource.sort = this.sort; 
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource.data);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  confirmarBorrado(id){
    this.confirmado=id;
  };

  cancelarBorrado(){
    this.confirmado= null;
  };

  deleteBibliografia(id) {
    this.dataService.delBibliografia(id).subscribe(
      result => {
          if(result.code == 200){
            this.listarbibliografias();
          }else{
            alert("Error al borrar");
            console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

}
