import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ConectorService } from '../servicios/conector-service';
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  public nombreparacabecera: string;
  
  constructor(
    private  conector: ConectorService, // lo utilizo para activar el menu cuando se loguee
    private router: Router
  ) {
   }

  ngOnInit() {
    // sirve para presentar el menu si está logado
    if (localStorage.getItem('autorizado') == '1') {
      this.nombreparacabecera = localStorage.getItem('nombre') + " " + localStorage.getItem('apellidos');
      this.conector.changeMessage(this.nombreparacabecera); // cargamos el valor en el servicio para que cambie en la cabecera
    }
    this.conector.customMessage.subscribe(msg => this.nombreparacabecera = msg);
  }

}
