import { Component, OnInit } from '@angular/core';
import { Sitios } from '../modelos/sitios';
import { SitiosService } from "../servicios/sitios.service";
import { Paises } from '../modelos/paises';
import { PaisesService } from "../servicios/paises.service";
import { Router, Params, ActivatedRoute } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-modificarsitio',
  templateUrl: './modificarsitio.component.html',
  styleUrls: ['./modificarsitio.component.css'],
  providers: [SitiosService, PaisesService]
})
export class ModificarsitioComponent implements OnInit {
  public sitio: Sitios;
  public paises: Paises;
  public titulo: string;
  public respuesta: number; // lo usamos para recibir los errores del servidor
  public mensajerespuesta: string; //lo usamos para presentar el mensaje retornado

  constructor(
    private _sitiosService: SitiosService,
    private _paisesService: PaisesService,
    private route: ActivatedRoute,
    private router: Router
  ) { 
    this.sitio = new Sitios(null, null, "", null , null, "", "");
    this.titulo = "Modificar Sitio";
    this.respuesta=0; // inicializamos a 0 como respuesta correcta
    this.mensajerespuesta=null; // inicializamos vacio como respuesta correcta
  }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
    this.cargarpaises();
    this.leerSitio();
  }

  ngAfterViewInit() {
    // va al top de la página cuando se entra
    window.scrollTo(0, 0);
  }

  cargarpaises(){
    console.log("Cargando Paises");
    this._paisesService.getPaisesselect().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.paises = result.data;
              console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  onSubmit() {
    //console.log(this.posicion);
    this.updateSitio();
  }

  leerSitio() {
    this.route.params.forEach((params: Params) => {
      let id=params['id'];

      this._sitiosService.getSitio(id).subscribe(
        result => {
            if(result.code == 200){
              console.log(result);
              this.sitio = result.data;
            }else{
              alert("Error al leer registro");
              console.log(result);
            }
        },
        error => {
            console.log(<any>error);
        }
      );
    });
  }

  updateSitio() {
    console.log("entro en update");
    console.log(this.sitio);
    
    this.route.params.forEach((params: Params) => {
      let id=params['id'];
      console.log(id);
      this._sitiosService.updateSitio(id, this.sitio).subscribe(

        result => {
          console.log(result);
          if(result.code != 200){
            this.respuesta=result.code; // cargamos el codigo de respuesta recibido
            this.mensajerespuesta=result.message; // cargamos el mensaje recibido
            //console.log(result);
          }else{
            //console.log(result);
            this.respuesta=result.code; // cargamos el codigo de respuesta recibido
            this.router.navigate(['/listarsitios']);
          }
        },
        error => {
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
          console.log(<any>error);
        }
      );
    });
  }
}
