import { Component, OnInit, ViewChild } from '@angular/core';
import { Materiales } from "../modelos/materiales";
import { MaterialesService } from "../servicios/materiales.service";
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-listarmateriales',
  templateUrl: './listarmateriales.component.html',
  styleUrls: ['./listarmateriales.component.css'],
  providers: [MaterialesService]
})
export class ListarmaterialesComponent implements OnInit {
  public material: Materiales;
  public confirmado:number;
  public titulo: string;
  public ruta: string;
  public noImagen: string;
  
  displayedColumns = ['idMaterial', 'descripcion', 'urlImagen', 'modificar', 'borrar'];
  dataSource: any;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    private dataService: MaterialesService,
    private router: Router
  ) { }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
    this.listarmateriales();
    this.confirmado=null;
    this.titulo="Listado de Materiales";
    this.ruta=GLOBAL.urlImagenes;
    this.noImagen = GLOBAL.urlNoImagen;
  }

  ngAfterViewInit() {
    // va al top de la página cuando se entra
    window.scrollTo(0, 0);
  }

  // Saca el listado de todos los registros
  listarmateriales(){

    /* carga el servicio para datatable de angular material */
    this.dataService.getMateriales().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.dataSource = new MatTableDataSource();  
              this.dataSource.data = result.data; 
              this.dataSource.sort = this.sort; 
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource.data);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  confirmarBorrado(id){
    this.confirmado=id;
  };

  cancelarBorrado(){
    this.confirmado= null;
  };

  deleteMaterial(id) {
    this.dataService.delMaterial(id).subscribe(
      result => {
          if(result.code == 200){
            this.listarmateriales();
          }else{
            alert("Error al borrar");
            console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

}
