export class Bibliografias {
    constructor(
      public idGrafito: number,
      public idBibliografia: number,
      public referencia: string,
      public usuAlta: number,
      public usuMod: number,
      public created_at: string,
      public updated_at: string,
      public glosarioDescripcion: string,
      public urlImagen: string
    ) {}
  }