import { Component, OnInit } from '@angular/core';
import { Tiposiconograficos } from '../modelos/tiposiconograficos';
import { TiposiconograficosService } from "../servicios/tiposiconograficos.service";
import { Router } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-creartipoiconografico',
  templateUrl: './creartipoiconografico.component.html',
  styleUrls: ['./creartipoiconografico.component.css'],
  providers: [TiposiconograficosService]
})
export class CreartipoiconograficoComponent implements OnInit {
  public tipoicono: Tiposiconograficos;
  public titulo: string;
  public filesToUpload: File[];
  public resultUpload: any;
  public respuesta: number; // lo usamos para recibir los errores del servidor
  public mensajerespuesta: string; //lo usamos para presentar el mensaje retornado

  constructor(
    private _tipoiconoService: TiposiconograficosService,
    private router: Router
  ) { 
    this.tipoicono = new Tiposiconograficos(null, "", null , null, "", "", "", "");
    this.titulo = "Crear Tipo Iconográfico";
    this.respuesta=0; // inicializamos a 0 como respuesta correcta
    this.mensajerespuesta=null; // inicializamos vacio como respuesta correcta
  }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
  }

  ngAfterViewInit() {
    // va al top de la página cuando se entra
    window.scrollTo(0, 0);
  }

  onSubmit() {
    //Para hacer la subida del fichero
    console.log('submit ok');

    if(this.filesToUpload && this.filesToUpload.length >= 1){
      this._tipoiconoService.makeFileRequest(GLOBAL.url+'upload-file', [], this.filesToUpload).then((result)=>{
          console.log(result);
          this.resultUpload=result;
          this.tipoicono.urlImagen = this.resultUpload.filename;
          this.guardamosTipoicono();
        }, (error) => {
          console.log(error);
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
        });
      } else {
        this.guardamosTipoicono();
      }
    }

  guardamosTipoicono(){
    this._tipoiconoService.addTipoiconografico(this.tipoicono).subscribe(
      result => {
        if(result.code == 200){
          console.log(result);
          this.respuesta=result.code; // cargamos el codigo de respuesta recibido
          this.router.navigate(['/listartiposiconograficos']);
        }else{
          this.respuesta=result.code; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta=result.message; // cargamos el mensaje recibido
          console.log(result);
        }
      },
      error => {
          console.log(<any>error);
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
      }
    );
  }

  fileChangeEvent(fileInput: any){
    this.filesToUpload = <Array<File>>fileInput.target.files;
    // Mueve el nombre del fichero al imput file del html, sin esto no se ve
    this.tipoicono.urlImagen = fileInput.target.files.item(0).name;
		//console.log(fileInput.target.files.item(0).name);
  }

}
