import { Component, OnInit } from '@angular/core';
import { Bibliografias } from '../modelos/bibliografias';
import { BibliografiasService } from "../servicios/bibliografias.service";
import { Grafitos } from '../modelos/grafitos';
import { GrafitosService } from "../servicios/grafitos.service";
import { Router, Params, ActivatedRoute } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-modificarbibliografia',
  templateUrl: './modificarbibliografia.component.html',
  styleUrls: ['./modificarbibliografia.component.css'],
  providers: [BibliografiasService, GrafitosService]
})
export class ModificarbibliografiaComponent implements OnInit {
  public bibliografia: Bibliografias;
  public grafitos: Grafitos;
  public titulo: string;
  public ruta: string;
  public filesToUpload: File[];
  public resultUpload: any;
  public respuesta: number; // lo usamos para recibir los errores del servidor
  public mensajerespuesta: string; //lo usamos para presentar el mensaje retornado

  constructor(
    private _bibliografiasService: BibliografiasService,
    private _grafitosService: GrafitosService,
    private route: ActivatedRoute,
    private router: Router
  ) { 
    this.bibliografia = new Bibliografias(null, null, "", null , null, "", "", "", "");
    this.titulo = "Modificar Bibliografía";
    this.respuesta=0; // inicializamos a 0 como respuesta correcta
    this.mensajerespuesta=null; // inicializamos vacio como respuesta correcta
    this.ruta=GLOBAL.urlImagenes;
  }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
    this.cargagrafitos();
    this.leerBibliografia();
  }

  ngAfterViewInit() {
    // va al top de la página cuando se entra
    window.scrollTo(0, 0);
  }

  onSubmit() {
    //console.log(this.bibliografia);

    if(this.filesToUpload && this.filesToUpload.length >= 1){
			this._bibliografiasService.makeFileRequest(GLOBAL.url+'upload-file', [], this.filesToUpload).then((result) => {
				console.log(result);

				this.resultUpload = result;
				this.bibliografia.urlImagen = this.resultUpload.filename;
				this.updateBibliografia();

			}, (error) =>{
				console.log(error);
			});
		}else{
			this.updateBibliografia();	
		}
  }

  leerBibliografia() {
    this.route.params.forEach((params: Params) => {
      let id=params['id'];

      this._bibliografiasService.getBibliografia(id).subscribe(
        result => {
            if(result.code == 200){
              console.log(result);
              this.bibliografia = result.data;
            }else{
              alert("Error al leer registro");
              console.log(result);
            }
        },
        error => {
            console.log(<any>error);
        }
      );
    });
  }

  updateBibliografia() {
    this.route.params.forEach((params: Params) => {
      let id=params['id'];
      this._bibliografiasService.updateBibliografia(id, this.bibliografia).subscribe(

        result => {
          console.log(result);
          if(result.code != 200){
            this.respuesta=result.code; // cargamos el codigo de respuesta recibido
            this.mensajerespuesta=result.message; // cargamos el mensaje recibido
            //console.log(result);
          }else{
            //console.log(result);
            this.respuesta=result.code; // cargamos el codigo de respuesta recibido
            this.router.navigate(['/listarbibliografias']);
          }
        },
        error => {
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
          console.log(<any>error);
        }
      );
    });
  }
  
  cargagrafitos(){
    console.log("Cargando Grafitos");
    this._grafitosService.getGrafitosSelect().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.grafitos = result.data;
              console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  fileChangeEvent(fileInput: any){
    this.filesToUpload = <Array<File>>fileInput.target.files;
    // Mueve el nombre del fichero al imput file del html, sin esto no se ve
    this.bibliografia.urlImagen = fileInput.target.files.item(0).name;
		console.log(this.filesToUpload);
  }

}
