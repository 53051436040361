import { Component, OnInit } from '@angular/core';
import { Proyectos } from '../modelos/proyectos';
import { ProyectosService } from "../servicios/proyectos.service";
import { Router, Params, ActivatedRoute } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-modificarproyecto',
  templateUrl: './modificarproyecto.component.html',
  styleUrls: ['./modificarproyecto.component.css'],
  providers: [ProyectosService]
})
export class ModificarproyectoComponent implements OnInit {
  public proyecto: Proyectos;
  public titulo: string;
  public ruta: string;
  public filesToUpload: File[];
  public resultUpload: any;
  public respuesta: number; // lo usamos para recibir los errores del servidor
  public mensajerespuesta: string; //lo usamos para presentar el mensaje retornado

  constructor(
    private _proyectosService: ProyectosService,
    private route: ActivatedRoute,
    private router: Router
  ) { 
    this.proyecto = new Proyectos(null, "", "", "", null , null, "", "", "", "");
    this.titulo = "Modificar Proyecto";
    this.ruta=GLOBAL.urlImagenes;
    this.respuesta=0; // inicializamos a 0 como respuesta correcta
    this.mensajerespuesta=null; // inicializamos vacio como respuesta correcta
  }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
    this.leerProyecto();
  }

  ngAfterViewInit() {
    // va al top de la página cuando se entra
    window.scrollTo(0, 0);
  }

  onSubmit() {
    //console.log(this.posicion);

    if(this.filesToUpload && this.filesToUpload.length >= 1){
			this._proyectosService.makeFileRequest(GLOBAL.url+'upload-file', [], this.filesToUpload).then((result) => {
				console.log(result);

				this.resultUpload = result;
				this.proyecto.urlImagen = this.resultUpload.filename;
				this.updateProyecto();

			}, (error) =>{
				console.log(error);
			});
		}else{
			this.updateProyecto();	
		}
  }

  leerProyecto() {
    this.route.params.forEach((params: Params) => {
      let id=params['id'];

      this._proyectosService.getProyecto(id).subscribe(
        result => {
            if(result.code == 200){
              console.log(result);
              this.proyecto = result.data;
            }else{
              alert("Error al leer registro");
              console.log(result);
            }
        },
        error => {
            console.log(<any>error);
        }
      );
    });
  }

  updateProyecto() {
    this.route.params.forEach((params: Params) => {
      let id=params['id'];
      this._proyectosService.updateProyecto(id, this.proyecto).subscribe(

        result => {
          console.log(result);
          if(result.code != 200){
            this.respuesta=result.code; // cargamos el codigo de respuesta recibido
            this.mensajerespuesta=result.message; // cargamos el mensaje recibido
            //console.log(result);
          }else{
            //console.log(result);
            this.respuesta=result.code; // cargamos el codigo de respuesta recibido
            this.router.navigate(['/listarproyectos']);
          }
        },
        error => {
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
          console.log(<any>error);
        }
      );
    });
  }

  fileChangeEvent(fileInput: any){
    this.filesToUpload = <Array<File>>fileInput.target.files;
    // Mueve el nombre del fichero al imput file del html, sin esto no se ve
    this.proyecto.urlImagen = fileInput.target.files.item(0).name;
		console.log(this.filesToUpload);
  }

}
