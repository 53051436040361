import { Component, OnInit } from '@angular/core';
import { Calcos } from '../modelos/calcos';
import { CalcosService } from "../servicios/calcos.service";
import { Dibujos } from '../modelos/dibujos';
import { DibujosService } from "../servicios/dibujos.service";
import { Grafitos } from '../modelos/grafitos';
import { GrafitosService } from "../servicios/grafitos.service";
import { Router, Params, ActivatedRoute } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-modificarcalco',
  templateUrl: './modificarcalco.component.html',
  styleUrls: ['./modificarcalco.component.css'],
  providers: [CalcosService, GrafitosService, DibujosService]
})
export class ModificarcalcoComponent implements OnInit {
  public calco: Calcos;
  public grafitos: Grafitos;
  public dibujos: Dibujos;
  public titulo: string;
  public ruta: string;
  public ficheroselecionado: string;
  public filesToUpload: File[];
  public resultUpload: any;
  public respuesta: number; // lo usamos para recibir los errores del servidor
  public mensajerespuesta: string; //lo usamos para presentar el mensaje retornado

  constructor(
    private _calcosService: CalcosService,
    private _dibujosService: DibujosService,
    private _grafitosService: GrafitosService,
    private route: ActivatedRoute,
    private router: Router
  ) { 
    this.calco = new Calcos(null, null, null, "", "", "", "" , "", null, null , "", "");
    this.titulo = "Modificar Calco";
    this.ruta=GLOBAL.urlImagenes;
    this.respuesta=0; // inicializamos a 0 como respuesta correcta
    this.mensajerespuesta=null; // inicializamos vacio como respuesta correcta
  }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
    this.cargagrafitos();
    this.cargadibujos();
    this.leerCalcos();
  }

  ngAfterViewInit() {
    // va al top de la página cuando se entra
    window.scrollTo(0, 0);
  }

  onSubmit() {
    //console.log(this.calco);

    if(this.filesToUpload && this.filesToUpload.length >= 1){
			this._calcosService.makeFileRequest(GLOBAL.url+'upload-file', [], this.filesToUpload).then((result) => {
				console.log(result);

				this.resultUpload = result;
				this.calco.urlImagen = this.resultUpload.filename;
				this.updateCalco();

			}, (error) =>{
				console.log(error);
			});
		}else{
			this.updateCalco();	
		}
  }

  leerCalcos() {
    this.route.params.forEach((params: Params) => {
      let id=params['id'];

      this._calcosService.getCalco(id).subscribe(
        result => {
            if(result.code == 200){
              console.log(result);
              this.calco = result.data;
            }else{
              alert("Error al leer registro");
              console.log(result);
            }
        },
        error => {
            console.log(<any>error);
        }
      );
    });
  }

  updateCalco() {
    this.route.params.forEach((params: Params) => {
      let id=params['id'];
      this._calcosService.updateCalco(id, this.calco).subscribe(

        result => {
          console.log(result);
          if(result.code != 200){
            this.respuesta=result.code; // cargamos el codigo de respuesta recibido
            this.mensajerespuesta=result.message; // cargamos el mensaje recibido
            //console.log(result);
          }else{
            //console.log(result);
            this.respuesta=result.code; // cargamos el codigo de respuesta recibido
            this.router.navigate(['/listarcalcos']);
          }
        },
        error => {
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
          console.log(<any>error);
        }
      );
    });
  }
  
  cargagrafitos(){
    console.log("Cargando Grafitos");
    this._grafitosService.getGrafitosSelect().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.grafitos = result.data;
              console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  cargadibujos(){
    console.log("Cargando Dibujos");
    this._dibujosService.getDibujosSelect().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.dibujos = result.data;
              console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  fileChangeEvent(fileInput: any){
    this.filesToUpload = <Array<File>>fileInput.target.files;
    // Mueve el nombre del fichero al imput file del html, sin esto no se ve
    this.calco.urlImagen = fileInput.target.files.item(0).name;
		console.log(this.filesToUpload);
  }

}
