import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// importar componentes
import { HomeComponent } from "./home/home.component";
import { CrearareaComponent } from "./creararea/creararea.component";
import { ListarareasComponent } from "./listarareas/listarareas.component";
import { ModificarareaComponent } from "./modificararea/modificararea.component";
import { CrearbibliografiaComponent } from "./crearbibliografia/crearbibliografia.component";
import { ListarbibliografiasComponent } from "./listarbibliografias/listarbibliografias.component";
import { ModificarbibliografiaComponent } from "./modificarbibliografia/modificarbibliografia.component";
import { CrearcalcoComponent } from "./crearcalco/crearcalco.component";
import { ListarcalcosComponent } from "./listarcalcos/listarcalcos.component";
import { ModificarcalcoComponent } from "./modificarcalco/modificarcalco.component";
import { CrearcategoriaComponent } from "./crearcategoria/crearcategoria.component";
import { ListarcategoriasComponent } from "./listarcategorias/listarcategorias.component";
import { ModificarcategoriaComponent } from "./modificarcategoria/modificarcategoria.component";
import { CreardibujoComponent } from "./creardibujo/creardibujo.component";
import { ListardibujosComponent } from "./listardibujos/listardibujos.component";
import { ModificardibujoComponent } from "./modificardibujo/modificardibujo.component";
import { CrearfotografiaComponent } from "./crearfotografia/crearfotografia.component";
import { ListarfotografiasComponent } from "./listarfotografias/listarfotografias.component";
import { ModificarfotografiaComponent } from "./modificarfotografia/modificarfotografia.component";
import { CreargrafitoComponent } from "./creargrafito/creargrafito.component";
import { ListargrafitosComponent } from "./listargrafitos/listargrafitos.component";
import { ModificargrafitoComponent } from "./modificargrafito/modificargrafito.component";
import { CrearmaterialComponent } from "./crearmaterial/crearmaterial.component";
import { ListarmaterialesComponent } from "./listarmateriales/listarmateriales.component";
import { ModificarmaterialComponent } from "./modificarmaterial/modificarmaterial.component";
import { CrearpaisComponent } from "./crearpais/crearpais.component";
import { ListarpaisesComponent } from "./listarpaises/listarpaises.component";
import { ModificarpaisComponent } from "./modificarpais/modificarpais.component";
import { CrearposicionComponent } from "./crearposicion/crearposicion.component";
import { ListarposicionesComponent } from "./listarposiciones/listarposiciones.component";
import { ModificarposicionComponent } from "./modificarposicion/modificarposicion.component";
import { CrearproyectoComponent } from "./crearproyecto/crearproyecto.component";
import { ListarproyectosComponent } from "./listarproyectos/listarproyectos.component";
import { ModificarproyectoComponent } from "./modificarproyecto/modificarproyecto.component";
import { CrearseccionComponent } from "./crearseccion/crearseccion.component";
import { ListarseccionesComponent } from "./listarsecciones/listarsecciones.component";
import { ModificarseccionComponent } from "./modificarseccion/modificarseccion.component";
import { CrearsituacionComponent } from "./crearsituacion/crearsituacion.component";
import { ListarsituacionesComponent } from "./listarsituaciones/listarsituaciones.component";
import { ModificarsituacionComponent } from "./modificarsituacion/modificarsituacion.component";

import { CrearsitioComponent } from "./crearsitio/crearsitio.component";
import { ListarsitiosComponent } from "./listarsitios/listarsitios.component";
import { ModificarsitioComponent } from "./modificarsitio/modificarsitio.component";

import { CreartecnicaComponent } from "./creartecnica/creartecnica.component";
import { ListartecnicasComponent } from "./listartecnicas/listartecnicas.component";
import { ModificartecnicaComponent } from "./modificartecnica/modificartecnica.component";
import { CreartipoiconograficoComponent } from "./creartipoiconografico/creartipoiconografico.component";
import { ListartiposiconograficosComponent } from "./listartiposiconograficos/listartiposiconograficos.component";
import { ModificartipoiconograficoComponent } from "./modificartipoiconografico/modificartipoiconografico.component";
import { CreartrazadoComponent } from "./creartrazado/creartrazado.component";
import { ListartrazadosComponent } from "./listartrazados/listartrazados.component";
import { ModificartrazadoComponent } from "./modificartrazado/modificartrazado.component";
import { CrearubicacionComponent } from "./crearubicacion/crearubicacion.component";
import { ListarubicacionesComponent } from "./listarubicaciones/listarubicaciones.component";
import { ModificarubicacionComponent } from "./modificarubicacion/modificarubicacion.component";
import { CrearusuarioComponent } from "./crearusuario/crearusuario.component";
import { ListarusuariosComponent } from "./listarusuarios/listarusuarios.component";
import { ModificarusuarioComponent } from "./modificarusuario/modificarusuario.component";
// importar componente 404
import { Pagina404Component } from "./pagina404/pagina404.component";


const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "home", component: HomeComponent },
  { path: "creararea", component: CrearareaComponent },
  { path: "listarareas", component: ListarareasComponent },
  { path: "modificararea/:id", component: ModificarareaComponent },
  { path: "crearbibliografia", component: CrearbibliografiaComponent },
  { path: "listarbibliografias", component: ListarbibliografiasComponent },
  { path: "modificarbibliografia/:id", component: ModificarbibliografiaComponent },
  { path: "crearcalco", component: CrearcalcoComponent },
  { path: "listarcalcos", component: ListarcalcosComponent },
  { path: "modificarcalco/:id", component: ModificarcalcoComponent },
  { path: "crearcategoria", component: CrearcategoriaComponent },
  { path: "listarcategorias", component: ListarcategoriasComponent },
  { path: "modificarcategoria/:id", component: ModificarcategoriaComponent },
  { path: "creardibujo", component: CreardibujoComponent },
  { path: "listardibujos", component: ListardibujosComponent },
  { path: "modificardibujo/:id", component: ModificardibujoComponent },
  { path: "crearfotografia", component: CrearfotografiaComponent },
  { path: "listarfotografias", component: ListarfotografiasComponent },
  { path: "modificarfotografia/:id", component: ModificarfotografiaComponent },
  { path: "creargrafito", component: CreargrafitoComponent },
  { path: "listargrafitos", component: ListargrafitosComponent },
  { path: "modificargrafito/:id", component: ModificargrafitoComponent },
  { path: "crearmaterial", component: CrearmaterialComponent },
  { path: "listarmateriales", component: ListarmaterialesComponent },
  { path: "modificarmaterial/:id", component: ModificarmaterialComponent },
  { path: "crearpais", component: CrearpaisComponent },
  { path: "listarpaises", component: ListarpaisesComponent },
  { path: "modificarpais/:id", component: ModificarpaisComponent },
  { path: "crearposicion", component: CrearposicionComponent },
  { path: "listarposiciones", component: ListarposicionesComponent },
  { path: "modificarposicion/:id", component: ModificarposicionComponent },
  { path: "crearproyecto", component: CrearproyectoComponent },
  { path: "listarproyectos", component: ListarproyectosComponent },
  { path: "modificarproyecto/:id", component: ModificarproyectoComponent },
  { path: "crearseccion", component: CrearseccionComponent },
  { path: "listarsecciones", component: ListarseccionesComponent },
  { path: "modificarseccion/:id", component: ModificarseccionComponent },
  { path: "crearsituacion", component: CrearsituacionComponent },
  { path: "listarsituaciones", component: ListarsituacionesComponent },
  { path: "modificarsituacion/:id", component: ModificarsituacionComponent },

  { path: "crearsitio", component: CrearsitioComponent },
  { path: "listarsitios", component: ListarsitiosComponent },
  { path: "modificarsitio/:id", component: ModificarsitioComponent },

  { path: "creartecnica", component: CreartecnicaComponent },
  { path: "listartecnicas", component: ListartecnicasComponent },
  { path: "modificartecnica/:id", component: ModificartecnicaComponent },
  { path: "creartipoiconografico", component: CreartipoiconograficoComponent },
  { path: "listartiposiconograficos", component: ListartiposiconograficosComponent },
  { path: "modificartipoiconografico/:id", component: ModificartipoiconograficoComponent },
  { path: "creartrazado", component: CreartrazadoComponent },
  { path: "listartrazados", component: ListartrazadosComponent },
  { path: "modificartrazado/:id", component: ModificartrazadoComponent },
  { path: "crearubicacion", component: CrearubicacionComponent },
  { path: "listarubicaciones", component: ListarubicacionesComponent },
  { path: "modificarubicacion/:id", component: ModificarubicacionComponent },
  { path: "crearusuario", component: CrearusuarioComponent },
  { path: "listarusuarios", component: ListarusuariosComponent },
  { path: "modificarusuario/:id", component: ModificarusuarioComponent },
  { path: "404", component: Pagina404Component },
  { path: "**", component: Pagina404Component }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
