import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ConectorService {

  private nombreparacabecera = new BehaviorSubject<string>('');
  public customMessage = this.nombreparacabecera.asObservable();

  constructor() {}

  public changeMessage(msg: string): void {
    this.nombreparacabecera.next(msg);
  }
}