import { Component, OnInit, ViewChild } from '@angular/core';
import { Sitios } from "../modelos/sitios";
import { SitiosService } from "../servicios/sitios.service";
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-listarsitios',
  templateUrl: './listarsitios.component.html',
  styleUrls: ['./listarsitios.component.css'],
  providers: [SitiosService]
})
export class ListarsitiosComponent implements OnInit {
  public sitio: Sitios;
  public confirmado:number;
  public titulo: string;
  public ruta: string;
  public noImagen: string;
  
  displayedColumns = ['idSitio', 'descripcion', 'desSitio', 'modificar', 'borrar'];
  dataSource: any;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    private dataService: SitiosService,
    private router: Router
  ) { }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
    this.listarsitios();
    this.confirmado=null;
    this.titulo="Listado de Sitios";
  }

  ngAfterViewInit() {
    // va al top de la página cuando se entra
    window.scrollTo(0, 0);
  }

  // Saca el listado de todos los registros
  listarsitios(){

    /* carga el servicio para datatable de angular material */
    this.dataService.getSitios().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.dataSource = new MatTableDataSource();  
              this.dataSource.data = result.data; 
              this.dataSource.sort = this.sort; 
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource.data);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  confirmarBorrado(id){
    this.confirmado=id;
  };

  cancelarBorrado(){
    this.confirmado= null;
  };

  deleteSitio(id) {
    this.dataService.delSitio(id).subscribe(
      result => {
          if(result.code == 200){
            this.listarsitios();
          }else{
            alert("Error al borrar");
            console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

}
