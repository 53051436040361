import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Calcos } from '../modelos/calcos';
import { GLOBAL } from './global';

@Injectable()
export class CalcosService {
  public url: string;
  

  constructor (
    public http: HttpClient
  ){
    this.url = GLOBAL.url;
  }

  getCalcos(): Observable<any>{
    return this.http.get(this.url+'listarcalcos');
  }

  addCalco(calcos: Calcos): Observable<any>{
    let json = JSON.stringify(calcos);
    let params = "json="+json;
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
     
    return this.http.post(this.url+'crearcalco', params, {headers: headers});
  }

  delCalco(id): Observable<any>{
    return this.http.get(this.url+'borrarcalco/'+id);
  }

  getCalco(id): Observable<any>{
    return this.http.get(this.url+'leercalco/'+id);
  }

  updateCalco(id, Calcos: Calcos): Observable<any>{
    let json = JSON.stringify(Calcos);
    let params = "json="+json;
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
     
    return this.http.post(this.url+'modificarcalco/'+id, params, {headers: headers});
  }

	makeFileRequest(url: string, params: Array<string>, files: Array<File>){
		return new Promise((resolve, reject)=>{
			var formData: any = new FormData();
			var xhr = new XMLHttpRequest();

			for(var i = 0; i < files.length; i++){
				formData.append('uploads[]', files[i], files[i].name);
			}

			xhr.onreadystatechange = function(){
				if(xhr.readyState == 4){
					if(xhr.status == 200){
						resolve(JSON.parse(xhr.response));
					}else{
						reject(xhr.response);
					}
				}
			};
			xhr.open('POST', url, true);
			xhr.send(formData);
		});
	}
}