export class Usuarios {
    constructor(
      public idUsuario: number,
      public nombre: string,
      public apellidos: string,
      public email: string,
      public password: string,
      public urlImagen: string,
      public usuAlta: number,
      public usuMod: number,
      public created_at: string,
      public updated_at: string,
      public remember_token: string
    ) {}
  }