export class Calcos {
    constructor(
      public idGrafito: number,
      public idDibujo: number,
      public idCalco: number,
      public autor: string,
      public fecha: string,
      public identificacion: string,
      public fichero: string,
      public urlImagen: string,
      public usuAlta: number,
      public usuMod: number,
      public created_at: string,
      public updated_at: string
    ) {}
  }