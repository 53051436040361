export class Sitios {
    constructor(
      public idSitio: number,
      public idPais: number,
      public desSitio: string,
      public usuAlta: number,
      public usuMod: number,
      public created_at: string,
      public updated_at: string
    ) {}
  }