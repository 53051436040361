import { Component, OnInit , ViewChild } from '@angular/core';
import { Trazados } from "../modelos/trazados";
import { TrazadosService } from "../servicios/trazados.service";
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-listartrazados',
  templateUrl: './listartrazados.component.html',
  styleUrls: ['./listartrazados.component.css'],
  providers: [TrazadosService]
})
export class ListartrazadosComponent implements OnInit {
  public trazado: Trazados;
  public confirmado:number;
  public titulo: string;
  public ruta: string;
  public noImagen: string;
  
  displayedColumns = ['idTrazado', 'descripcion', 'urlImagen', 'modificar', 'borrar'];
  dataSource: any;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    private dataService: TrazadosService,
    private router: Router
  ) { }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
    this.listartrazados();
    this.confirmado=null;
    this.titulo="Listado de Trazados";
    this.ruta=GLOBAL.urlImagenes;
    this.noImagen = GLOBAL.urlNoImagen;
  }

  ngAfterViewInit() {
    // va al top de la página cuando se entra
    window.scrollTo(0, 0);
  }

  // Saca el listado de todos los registros
  listartrazados(){

    /* carga el servicio para datatable de angular material */
    this.dataService.getTrazados().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.dataSource = new MatTableDataSource();  
              this.dataSource.data = result.data; 
              this.dataSource.sort = this.sort; 
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource.data);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  confirmarBorrado(id){
    this.confirmado=id;
  };

  cancelarBorrado(){
    this.confirmado= null;
  };

  deleteTrazado(id) {
    this.dataService.delTrazado(id).subscribe(
      result => {
          if(result.code == 200){
            this.listartrazados();
          }else{
            alert("Error al borrar");
            console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

}
