import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MatInputModule, MatPaginatorModule, MatProgressSpinnerModule, MatProgressBarModule,
  MatSortModule, MatTableModule } from "@angular/material";
import { NgxEditorModule } from 'ngx-editor';

// se crea en servicios para pasar parámetros entre componentes. Se pasa abajo en providers
import { ConectorService } from './servicios/conector-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CrearareaComponent } from './creararea/creararea.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Pagina404Component } from './pagina404/pagina404.component';
import { HomeComponent } from './home/home.component';
import { CabeceraComponent } from './cabecera/cabecera.component';
import { ContenidoComponent } from './contenido/contenido.component';
import { MenuComponent } from './menu/menu.component';
import { PieComponent } from './pie/pie.component';
import { ListarareasComponent } from './listarareas/listarareas.component';
import { ModificarareaComponent } from './modificararea/modificararea.component';
import { CrearbibliografiaComponent } from './crearbibliografia/crearbibliografia.component';
import { ListarbibliografiasComponent } from './listarbibliografias/listarbibliografias.component';
import { ModificarbibliografiaComponent } from './modificarbibliografia/modificarbibliografia.component';
import { CrearcalcoComponent } from './crearcalco/crearcalco.component';
import { ListarcalcosComponent } from './listarcalcos/listarcalcos.component';
import { ModificarcalcoComponent } from './modificarcalco/modificarcalco.component';
import { CrearcategoriaComponent } from './crearcategoria/crearcategoria.component';
import { ListarcategoriasComponent } from './listarcategorias/listarcategorias.component';
import { ModificarcategoriaComponent } from './modificarcategoria/modificarcategoria.component';
import { CreardibujoComponent } from './creardibujo/creardibujo.component';
import { ListardibujosComponent } from './listardibujos/listardibujos.component';
import { ModificardibujoComponent } from './modificardibujo/modificardibujo.component';
import { CrearfotografiaComponent } from './crearfotografia/crearfotografia.component';
import { ListarfotografiasComponent } from './listarfotografias/listarfotografias.component';
import { ModificarfotografiaComponent } from './modificarfotografia/modificarfotografia.component';
import { CreargrafitoComponent } from './creargrafito/creargrafito.component';
import { ListargrafitosComponent } from './listargrafitos/listargrafitos.component';
import { ModificargrafitoComponent } from './modificargrafito/modificargrafito.component';
import { CrearmaterialComponent } from './crearmaterial/crearmaterial.component';
import { ListarmaterialesComponent } from './listarmateriales/listarmateriales.component';
import { ModificarmaterialComponent } from './modificarmaterial/modificarmaterial.component';
import { CrearpaisComponent } from './crearpais/crearpais.component';
import { ListarpaisesComponent } from './listarpaises/listarpaises.component';
import { ModificarpaisComponent } from './modificarpais/modificarpais.component';
import { CrearposicionComponent } from './crearposicion/crearposicion.component';
import { ListarposicionesComponent } from './listarposiciones/listarposiciones.component';
import { ModificarposicionComponent } from './modificarposicion/modificarposicion.component';
import { CrearproyectoComponent } from './crearproyecto/crearproyecto.component';
import { ListarproyectosComponent } from './listarproyectos/listarproyectos.component';
import { ModificarproyectoComponent } from './modificarproyecto/modificarproyecto.component';
import { CrearseccionComponent } from './crearseccion/crearseccion.component';
import { ListarseccionesComponent } from './listarsecciones/listarsecciones.component';
import { ModificarseccionComponent } from './modificarseccion/modificarseccion.component';
import { CrearsituacionComponent } from './crearsituacion/crearsituacion.component';
import { ListarsituacionesComponent } from './listarsituaciones/listarsituaciones.component';
import { ModificarsituacionComponent } from './modificarsituacion/modificarsituacion.component';
import { CreartecnicaComponent } from './creartecnica/creartecnica.component';
import { ListartecnicasComponent } from './listartecnicas/listartecnicas.component';
import { ModificartecnicaComponent } from './modificartecnica/modificartecnica.component';
import { CreartipoiconograficoComponent } from './creartipoiconografico/creartipoiconografico.component';
import { ListartiposiconograficosComponent } from './listartiposiconograficos/listartiposiconograficos.component';
import { ModificartipoiconograficoComponent } from './modificartipoiconografico/modificartipoiconografico.component';
import { CreartrazadoComponent } from './creartrazado/creartrazado.component';
import { ListartrazadosComponent } from './listartrazados/listartrazados.component';
import { ModificartrazadoComponent } from './modificartrazado/modificartrazado.component';
import { CrearubicacionComponent } from './crearubicacion/crearubicacion.component';
import { ListarubicacionesComponent } from './listarubicaciones/listarubicaciones.component';
import { ModificarubicacionComponent } from './modificarubicacion/modificarubicacion.component';
import { CrearusuarioComponent } from './crearusuario/crearusuario.component';
import { ListarusuariosComponent } from './listarusuarios/listarusuarios.component';
import { ModificarusuarioComponent } from './modificarusuario/modificarusuario.component';
import { CrearsitioComponent } from './crearsitio/crearsitio.component';
import { ListarsitiosComponent } from './listarsitios/listarsitios.component';
import { ModificarsitioComponent } from './modificarsitio/modificarsitio.component';


@NgModule({
  declarations: [
    AppComponent,
    CrearareaComponent,
    Pagina404Component,
    HomeComponent,
    CabeceraComponent,
    ContenidoComponent,
    MenuComponent,
    PieComponent,
    ListarareasComponent,
    ModificarareaComponent,
    CrearbibliografiaComponent,
    ListarbibliografiasComponent,
    ModificarbibliografiaComponent,
    CrearcalcoComponent,
    ListarcalcosComponent,
    ModificarcalcoComponent,
    CrearcategoriaComponent,
    ListarcategoriasComponent,
    ModificarcategoriaComponent,
    CreardibujoComponent,
    ListardibujosComponent,
    ModificardibujoComponent,
    CrearfotografiaComponent,
    ListarfotografiasComponent,
    ModificarfotografiaComponent,
    CreargrafitoComponent,
    ListargrafitosComponent,
    ModificargrafitoComponent,
    CrearmaterialComponent,
    ListarmaterialesComponent,
    ModificarmaterialComponent,
    CrearpaisComponent,
    ListarpaisesComponent,
    ModificarpaisComponent,
    CrearposicionComponent,
    ListarposicionesComponent,
    ModificarposicionComponent,
    CrearproyectoComponent,
    ListarproyectosComponent,
    ModificarproyectoComponent,
    CrearseccionComponent,
    ListarseccionesComponent,
    ModificarseccionComponent,
    CrearsituacionComponent,
    ListarsituacionesComponent,
    ModificarsituacionComponent,
    CreartecnicaComponent,
    ListartecnicasComponent,
    ModificartecnicaComponent,
    CreartipoiconograficoComponent,
    ListartiposiconograficosComponent,
    ModificartipoiconograficoComponent,
    CreartrazadoComponent,
    ListartrazadosComponent,
    ModificartrazadoComponent,
    CrearubicacionComponent,
    ListarubicacionesComponent,
    ModificarubicacionComponent,
    CrearusuarioComponent,
    ListarusuariosComponent,
    ModificarusuarioComponent,
    CrearsitioComponent,
    ListarsitiosComponent,
    ModificarsitioComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    NgxEditorModule,
    BrowserAnimationsModule
  ],
  providers: [ConectorService],
  bootstrap: [AppComponent]
})
export class AppModule { }
