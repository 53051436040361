import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Dibujos } from '../modelos/dibujos';
import { GLOBAL } from './global';

@Injectable()
export class DibujosService {
  public url: string;
  

  constructor (
    public http: HttpClient
  ){
    this.url = GLOBAL.url;
  }

  getDibujos(): Observable<any>{
    return this.http.get(this.url+'listardibujos');
  }

  getDibujosSelect(): Observable<any>{
    return this.http.get(this.url+'listardibujosselect');
  }

  addDibujo(dibujos: Dibujos): Observable<any>{
    let json = JSON.stringify(dibujos);
    let params = "json="+json;
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
     
    return this.http.post(this.url+'creardibujo', params, {headers: headers});
  }

  delDibujo(id): Observable<any>{
    return this.http.get(this.url+'borrardibujo/'+id);
  }

  getDibujo(id): Observable<any>{
    return this.http.get(this.url+'leerdibujo/'+id);
  }

  updateDibujo(id, Dibujos: Dibujos): Observable<any>{
    let json = JSON.stringify(Dibujos);
    let params = "json="+json;
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
     
    return this.http.post(this.url+'modificardibujo/'+id, params, {headers: headers});
  }

	makeFileRequest(url: string, params: Array<string>, files: Array<File>){
		return new Promise((resolve, reject)=>{
			var formData: any = new FormData();
			var xhr = new XMLHttpRequest();

			for(var i = 0; i < files.length; i++){
				formData.append('uploads[]', files[i], files[i].name);
			}

			xhr.onreadystatechange = function(){
				if(xhr.readyState == 4){
					if(xhr.status == 200){
						resolve(JSON.parse(xhr.response));
					}else{
						reject(xhr.response);
					}
				}
			};
			xhr.open('POST', url, true);
			xhr.send(formData);
		});
	}
}