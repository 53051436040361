import { Component, OnInit } from '@angular/core';
import { Usuarios } from '../modelos/usuarios';
import { UsuariosService } from "../servicios/usuarios.service";
import { Router, Params, ActivatedRoute } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-modificarusuario',
  templateUrl: './modificarusuario.component.html',
  styleUrls: ['./modificarusuario.component.css'],
  providers: [UsuariosService]
})
export class ModificarusuarioComponent implements OnInit {
  public usuario: Usuarios;
  public titulo: string;
  public filesToUpload: File[];
  public resultUpload: any;
  public ruta: string;
  public errorupdate: number;

  constructor(
    private _usuariosService: UsuariosService,
    private route: ActivatedRoute,
    private router: Router
  ) { 
    this.usuario = new Usuarios(null, "", "", "", "", "", null, null, "", "", "");
    this.titulo = "Modificar Usuario";
    this.ruta=GLOBAL.urlImagenes;
  }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
    this.leerUsuario();
  }

  onSubmit() {
    console.log(this.usuario);

    if(this.filesToUpload && this.filesToUpload.length >= 1){
			this._usuariosService.makeFileRequest(GLOBAL.url+'upload-file', [], this.filesToUpload).then((result) => {
				console.log(result);

				this.resultUpload = result;
				this.usuario.urlImagen = this.resultUpload.filename;
				this.updateUsuario();

			}, (error) =>{
				console.log(error);
			});
		}else{
			this.updateUsuario();	
		}
  }

  leerUsuario() {
    this.route.params.forEach((params: Params) => {
      let id=params['id'];

      this._usuariosService.getUsuario(id).subscribe(
        result => {
            if(result.code == 200){
              console.log(result);
              this.usuario = result.data;
            }else{
              alert("Error al leer registro");
              console.log(result);
            }
        },
        error => {
            console.log(<any>error);
        }
      );
    });
  }

  updateUsuario() {
    this.route.params.forEach((params: Params) => {
      let id=params['id'];
      this._usuariosService.updateUsuario(id, this.usuario).subscribe(

        result => {
          console.log(result);
          if(result.code != 200){
            //console.log(result);
          }else{
            //console.log(result);
            this.router.navigate(['/listarusuarios']);
          }
        },
        error => {
          this.errorupdate = 1;
          console.log(<any>error);
        }
      );
    });
  }

  fileChangeEvent(fileInput: any){
		this.filesToUpload = <Array<File>>fileInput.target.files;
		console.log(this.filesToUpload);
  }

}
