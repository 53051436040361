import { Component, OnInit } from '@angular/core';
import { Situaciones } from '../modelos/situaciones';
import { SituacionesService } from "../servicios/situaciones.service";
import { Posiciones } from '../modelos/posiciones';
import { PosicionesService } from "../servicios/posiciones.service";
import { Router, Params, ActivatedRoute } from "@angular/router";
import {GLOBAL} from "../servicios/global";


@Component({
  selector: 'app-modificarsituacion',
  templateUrl: './modificarsituacion.component.html',
  styleUrls: ['./modificarsituacion.component.css'],
  providers: [SituacionesService, PosicionesService]
})
export class ModificarsituacionComponent implements OnInit {
  public situacion: Situaciones;
  public posiciones: Posiciones;
  public titulo: string;
  public ruta: string;
  public ficheroselecionado: string;
  public filesToUpload: File[];
  public resultUpload: any;
  public respuesta: number; // lo usamos para recibir los errores del servidor
  public mensajerespuesta: string; //lo usamos para presentar el mensaje retornado

  constructor(
    private _situacionesService: SituacionesService,
    private _posicionesService: PosicionesService,
    private route: ActivatedRoute,
    private router: Router
  ) { 
    this.situacion = new Situaciones(null, null, "", null , null, "", "", "", "");
    this.titulo = "Modificar Situación";
    this.ruta=GLOBAL.urlImagenes;
    this.respuesta=0; // inicializamos a 0 como respuesta correcta
    this.mensajerespuesta=null; // inicializamos vacio como respuesta correcta
  }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
    this.cargarposiciones();
    this.leerSituacion();
  }

  ngAfterViewInit() {
    // va al top de la página cuando se entra
    window.scrollTo(0, 0);
  }

  cargarposiciones(){
    console.log("Cargando Posiciones");
    this._posicionesService.getPosicionesselect().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.posiciones = result.data;
              console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  onSubmit() {
    //console.log(this.posicion);

    if(this.filesToUpload && this.filesToUpload.length >= 1){
			this._situacionesService.makeFileRequest(GLOBAL.url+'upload-file', [], this.filesToUpload).then((result) => {
				console.log(result);

				this.resultUpload = result;
				this.situacion.urlImagen = this.resultUpload.filename;
				this.updateSituacion();

			}, (error) =>{
				console.log(error);
			});
		}else{
			this.updateSituacion();	
		}
  }

  leerSituacion() {
    this.route.params.forEach((params: Params) => {
      let id=params['id'];

      this._situacionesService.getSituacion(id).subscribe(
        result => {
            if(result.code == 200){
              console.log(result);
              this.situacion = result.data;
            }else{
              alert("Error al leer registro");
              console.log(result);
            }
        },
        error => {
            console.log(<any>error);
        }
      );
    });
  }

  updateSituacion() {
    console.log("entro en update");
    console.log(this.situacion);
    
    this.route.params.forEach((params: Params) => {
      let id=params['id'];
      console.log(id);
      this._situacionesService.updateSituacion(id, this.situacion).subscribe(

        result => {
          console.log(result);
          if(result.code != 200){
            this.respuesta=result.code; // cargamos el codigo de respuesta recibido
            this.mensajerespuesta=result.message; // cargamos el mensaje recibido
            //console.log(result);
          }else{
            //console.log(result);
            this.respuesta=result.code; // cargamos el codigo de respuesta recibido
            this.router.navigate(['/listarsituaciones']);
          }
        },
        error => {
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
          console.log(<any>error);
        }
      );
    });
  }

  fileChangeEvent(fileInput: any){
    this.filesToUpload = <Array<File>>fileInput.target.files;
    // Mueve el nombre del fichero al imput file del html, sin esto no se ve
    this.situacion.urlImagen = fileInput.target.files.item(0).name;
		console.log(this.filesToUpload);
  }

}
