import { Component, OnInit } from '@angular/core';
import { Grafitos } from '../modelos/grafitos';
import { GrafitosService } from "../servicios/grafitos.service";
import { Materiales } from '../modelos/materiales';
import { MaterialesService } from "../servicios/materiales.service";
import { Tecnicas } from '../modelos/tecnicas';
import { TecnicasService } from "../servicios/tecnicas.service";
import { Posiciones } from '../modelos/posiciones';
import { PosicionesService } from "../servicios/posiciones.service";
import { Sitios } from '../modelos/sitios';
import { SitiosService } from "../servicios/sitios.service";
import { Situaciones } from '../modelos/situaciones';
import { SituacionesService } from "../servicios/situaciones.service";
import { Paises } from '../modelos/paises';
import { PaisesService } from "../servicios/paises.service";
import { Areas } from '../modelos/areas';
import { AreasService } from "../servicios/areas.service";
import { Ubicaciones } from '../modelos/ubicaciones';
import { UbicacionesService } from "../servicios/ubicaciones.service";
import { Tiposiconograficos } from '../modelos/tiposiconograficos';
import { TiposiconograficosService } from "../servicios/tiposiconograficos.service";
import { Categorias } from '../modelos/categorias';
import { CategoriasService } from "../servicios/categorias.service";
import { Proyectos } from '../modelos/proyectos';
import { ProyectosService } from "../servicios/proyectos.service";
import { Router, Params, ActivatedRoute } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-modificargrafito',
  templateUrl: './modificargrafito.component.html',
  styleUrls: ['./modificargrafito.component.css'],
  providers: [ProyectosService, CategoriasService, TiposiconograficosService, UbicacionesService, 
    AreasService, PaisesService, SituacionesService, SitiosService, TecnicasService, PosicionesService, MaterialesService, GrafitosService]
})
export class ModificargrafitoComponent implements OnInit {
  public proyectos: Proyectos;
  public categorias: Categorias;
  public tiposiconograficos: Tiposiconograficos;
  public ubicaciones: Ubicaciones;
  public areas: Areas;
  public paises: Paises;
  public situaciones: Situaciones;
  public tecnicas: Tecnicas;
  public posiciones: Posiciones;
  public sitios: Sitios;
  public materiales: Materiales;
  public grafito: Grafitos;
  public titulo: string;
  public ruta: string;
  public ficheroselecionado: string;
  public filesToUpload: File[];
  public resultUpload: any;
  public respuesta: number; // lo usamos para recibir los errores del servidor
  public mensajerespuesta: string; //lo usamos para presentar el mensaje retornado

  constructor(
    private _proyectosService: ProyectosService,
    private _categoriasService: CategoriasService,
    private _tiposiconograficosService: TiposiconograficosService,
    private _ubicacionesService: UbicacionesService,
    private _areasService: AreasService,
    private _paisesService: PaisesService,
    private _situacionesService: SituacionesService,
    private _tecnicasService: TecnicasService,
    private _posicionesService: PosicionesService,
    private _sitiosService: SitiosService,
    private _materialesService: MaterialesService,
    private _grafitosService: GrafitosService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.grafito = new Grafitos(null, "", "", "", "", "", "", null, null, "" , "", null, null, null, null, "", null, null, "", "", "", null, "", "", null, null, "", null, "", "", "", null, "", null, null, "", "", "");
    this.titulo = "Modificar Grafito";
    this.ruta=GLOBAL.urlImagenes;
    this.respuesta=0; // inicializamos a 0 como respuesta correcta
    this.mensajerespuesta=null; // inicializamos vacio como respuesta correcta
   }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
    this.cargaproyectos();
    this.cargacategorias();
    this.cargatiposiconograficos();
    this.cargaubicaciones();
    this.cargaareas();
    this.cargapaises();
    this.cargatecnicas();
    this.cargaposiciones();
    this.cargamateriales();
    this.leerGrafito();
  }

  ngAfterViewInit() {
    // va al top de la página cuando se entra
    window.scrollTo(0, 0);
  }

  onSubmit() {
    //console.log(this.bibliografia);

    if(this.filesToUpload && this.filesToUpload.length >= 1){
			this._grafitosService.makeFileRequest(GLOBAL.url+'upload-file', [], this.filesToUpload).then((result) => {
				console.log(result);

				this.resultUpload = result;
				this.grafito.urlImagen = this.resultUpload.filename;
				this.updateGrafito();

			}, (error) =>{
				console.log(error);
			});
		}else{
			this.updateGrafito();	
		}
  }

  leerGrafito() {
    this.route.params.forEach((params: Params) => {
      let id=params['id'];

      this._grafitosService.getGrafito(id).subscribe(
        result => {
            if(result.code == 200){
              console.log(result);
              this.grafito = result.data;
              this.cargasitios(this.grafito.idPais);
              this.cargasituaciones(this.grafito.idPosicion);
            }else{
              alert("Error al leer registro");
              console.log(result);
            }
        },
        error => {
            console.log(<any>error);
        }
      );
    });
  }


  updateGrafito() {
    this.route.params.forEach((params: Params) => {
      let id=params['id'];
      this._grafitosService.updateGrafito(id, this.grafito).subscribe(

        result => {
          console.log(result);
          if(result.code != 200){
            this.respuesta=result.code; // cargamos el codigo de respuesta recibido
            this.mensajerespuesta=result.message; // cargamos el mensaje recibido
            //console.log(result);
          }else{
            //console.log(result);
            this.respuesta=result.code; // cargamos el codigo de respuesta recibido
            this.router.navigate(['/listargrafitos']);
          }
        },
        error => {
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
          console.log(<any>error);
        }
      );
    });
  }

  fileChangeEvent(fileInput: any){
    this.filesToUpload = <Array<File>>fileInput.target.files;
    // Mueve el nombre del fichero al imput file del html, sin esto no se ve
    this.grafito.urlImagen = fileInput.target.files.item(0).name;
		console.log(this.filesToUpload);
  }

  cargaproyectos(){
    console.log("Cargando Proyectos");
    this._proyectosService.getProyectosselect().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.proyectos = result.data;
              console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  cargacategorias(){
    console.log("Cargando Categorías");
    this._categoriasService.getCategoriasselect().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.categorias = result.data;
              console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  cargatiposiconograficos(){
    console.log("Cargando Tipos Iconográficos");
    this._tiposiconograficosService.getTiposiconograficosselect().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.tiposiconograficos = result.data;
              console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  cargaubicaciones(){
    console.log("Cargando Ubicaciones");
    this._ubicacionesService.getUbicacionesselect().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.ubicaciones = result.data;
              console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  cargaareas(){
    console.log("Cargando Areas");
    this._areasService.getAreasselect().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.areas = result.data;
              console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  cargapaises(){
    console.log("Cargando Paises");
    this._paisesService.getPaisesselect().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.paises = result.data;
              console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  cargasituaciones(id){
    console.log("Cargando Situaciones");
    this._situacionesService.getSituacionesselect(id).subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.situaciones = result.data;
              console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }
  

  cargatecnicas(){
    console.log("Cargando Tecnicas");
    this._tecnicasService.getTecnicasselect().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.tecnicas = result.data;
              console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  cargasitios(id){
    console.log("Cargando Sitios");
    this._sitiosService.getSitiosselect(id).subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.sitios = result.data;
              console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  cargaposiciones(){
    console.log("Cargando Posiciones");
    this._posicionesService.getPosicionesselect().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.posiciones = result.data;
              console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  cargamateriales(){
    console.log("Cargando Materiales");
    this._materialesService.getMaterialesselect().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.materiales = result.data;
              console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

}
