import { Component, OnInit } from '@angular/core';
import { Materiales } from '../modelos/materiales';
import { MaterialesService } from "../servicios/materiales.service";
import { Router } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-crearmaterial',
  templateUrl: './crearmaterial.component.html',
  styleUrls: ['./crearmaterial.component.css'],
  providers: [MaterialesService]
})
export class CrearmaterialComponent implements OnInit {
  public material: Materiales;
  public titulo: string;
  public filesToUpload: File[];
  public resultUpload: any;
  public respuesta: number; // lo usamos para recibir los errores del servidor
  public mensajerespuesta: string; //lo usamos para presentar el mensaje retornado

  constructor(
    private _materialesService: MaterialesService,
    private router: Router
  ) { 
    this.material = new Materiales(null, "", null , null, "", "", "", "");
    this.titulo = "Crear Material";
    this.respuesta=0; // inicializamos a 0 como respuesta correcta
    this.mensajerespuesta=null; // inicializamos vacio como respuesta correcta
  }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
  }

  ngAfterViewInit() {
    // va al top de la página cuando se entra
    window.scrollTo(0, 0);
  }

  onSubmit() {
    //Para hacer la subida del fichero
    console.log('submit ok');

    if(this.filesToUpload && this.filesToUpload.length >= 1){
      this._materialesService.makeFileRequest(GLOBAL.url+'upload-file', [], this.filesToUpload).then((result)=>{
          console.log(result);
          this.resultUpload=result;
          this.material.urlImagen = this.resultUpload.filename;
          this.guardamosMaterial();
        }, (error) => {
          console.log(error);
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
        });
      } else {
        this.guardamosMaterial();
      }
    }

  guardamosMaterial(){
    this._materialesService.addMaterial(this.material).subscribe(
      result => {
        if(result.code == 200){
          console.log(result);
          this.respuesta=result.code; // cargamos el codigo de respuesta recibido
          this.router.navigate(['/listarmateriales']);
        }else{
          this.respuesta=result.code; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta=result.message; // cargamos el mensaje recibido
          console.log(result);
        }
      },
      error => {
          console.log(<any>error);
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
      }
    );
  }

  fileChangeEvent(fileInput: any){
    this.filesToUpload = <Array<File>>fileInput.target.files;
    // Mueve el nombre del fichero al imput file del html, sin esto no se ve
    this.material.urlImagen = fileInput.target.files.item(0).name;
		console.log(this.filesToUpload);
  }

}
