import { Component, OnInit , ViewChild } from '@angular/core';
import { Grafitos } from "../modelos/grafitos";
import { GrafitosService } from "../servicios/grafitos.service";
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-listargrafitos',
  templateUrl: './listargrafitos.component.html',
  styleUrls: ['./listargrafitos.component.css'],
  providers: [GrafitosService]
})
export class ListargrafitosComponent implements OnInit {
  public grafito: Grafitos;
  public confirmado:number;
  public titulo: string;
  public ruta: string;
  public noImagen: string;
  
  displayedColumns = ['urlImagen', 'publicado', 'idGrafito', 'nombre', 'desMaterial', 'desTecnica', 'desPosicion', 'desSituacion', 'desPais', 'desArea', 'desUbicacion', 'desTipoIcono', 'desCategoria', 'desProyecto', 'palabrasClave', 'modificar', 'borrar'];
  dataSource: any;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    private dataService: GrafitosService,
    private router: Router
  ) { }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
    this.listargrafitos();
    this.confirmado=null;
    this.titulo="Listado de Grafitos";
    this.ruta=GLOBAL.urlImagenes;
    this.noImagen = GLOBAL.urlNoImagen;
  }

  ngAfterViewInit() {
    // va al top de la página cuando se entra
    window.scrollTo(0, 0);
  }

  // Saca el listado de todos los registros
  listargrafitos(){

    /* carga el servicio para datatable de angular material */
    this.dataService.getGrafitos().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.dataSource = new MatTableDataSource();  
              this.dataSource.data = result.data; 
              this.dataSource.sort = this.sort; 
              this.dataSource.paginator = this.paginator;
              console.log(this.dataSource.data);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  confirmarBorrado(id){
    this.confirmado=id;
  };

  cancelarBorrado(){
    this.confirmado= null;
  };

  deleteGrafito(id) {
    this.dataService.delGrafito(id).subscribe(
      result => {
          if(result.code == 200){
            this.listargrafitos();
          }else{
            alert("Error al borrar");
            console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

}
