import { Component, OnInit } from '@angular/core';
import { Sitios } from '../modelos/sitios';
import { SitiosService } from "../servicios/sitios.service";
import { Paises } from '../modelos/paises';
import { PaisesService } from "../servicios/paises.service";
import { Router } from "@angular/router";
import {GLOBAL} from "../servicios/global";

@Component({
  selector: 'app-crearsitio',
  templateUrl: './crearsitio.component.html',
  styleUrls: ['./crearsitio.component.css'],
  providers: [SitiosService, PaisesService]
})
export class CrearsitioComponent implements OnInit {
  public sitio: Sitios;
  public paises: Paises;
  public titulo: string;
  public ruta: string;

  public respuesta: number; // lo usamos para recibir los errores del servidor
  public mensajerespuesta: string; //lo usamos para presentar el mensaje retornado

  constructor(
    private _sitiosService: SitiosService,
    private _paisesService: PaisesService,
    private router: Router
  ) { 
    this.sitio = new Sitios(null, null, "", null , null, "", "");
    this.titulo = "Crear Sitio";
    this.respuesta=0; // inicializamos a 0 como respuesta correcta
    this.mensajerespuesta=null; // inicializamos vacio como respuesta correcta
  }

  ngOnInit() {
    // sirve para redirigir a la home si no está logado
    if (localStorage.getItem('autorizado') != '1') {
      this.router.navigate([""]);
    }
    this.cargarpaises();
  }

  onSubmit() {
    //Para hacer la subida del fichero
    console.log('submit ok');
    this.guardamossitio();
    }

  cargarpaises(){
    console.log("Cargando Paises");
    this._paisesService.getPaisesselect().subscribe(
      result => {
          if(result.code != 200){
              console.log(result);
          }else{
              this.paises = result.data;
              console.log(result);
          }
      },
      error => {
          console.log(<any>error);
      }
    );
  }

  guardamossitio(){
    this._sitiosService.addSitio(this.sitio).subscribe(
      result => {
        if(result.code == 200){
          console.log(result);
          this.respuesta=result.code; // cargamos el codigo de respuesta recibido
          this.router.navigate(['/listarsitios']);
        }else{
          this.respuesta=result.code; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta=result.message; // cargamos el mensaje recibido
          console.log(result);
        }
      },
      error => {
          console.log(<any>error);
          this.respuesta=500; // cargamos el codigo de respuesta recibido
          this.mensajerespuesta='ERROR! CONSULTE CON EL ADMINISTRADOR'; // cargamos el mensaje recibido 
      }
    );
  }

}
