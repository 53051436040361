import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Usuarios } from '../modelos/usuarios';
import { GLOBAL } from './global';
import { stripGeneratedFileSuffix } from '@angular/compiler/src/aot/util';

@Injectable()
export class UsuariosService {
  public url: string;
  public identity;
  public token;
  

  constructor (
    public http: HttpClient
  ){
    this.url = GLOBAL.url;
  }

  validoUsuario(email, password): Observable<any>{

    console.log("recibo en validusuario");
    console.log(email);
    console.log(password);

    let json = JSON.stringify({"email":  new String(email), "pass": new String(password)});
    let params = "json="+json;
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
     
    console.log(params);

    return this.http.post(this.url+'login', params, {headers: headers});
  }
  

  getUsuarios(): Observable<any>{
    return this.http.get(this.url+'listarusuarios');
  }

  addUsuario(usuarios: Usuarios): Observable<any>{
    let json = JSON.stringify(usuarios);
    let params = "json="+json;
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
     
    return this.http.post(this.url+'crearusuario', params, {headers: headers});
  }

  delUsuario(id): Observable<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.get(this.url+'borrarusuario/'+id, { headers: headers });
  }

  getUsuario(id): Observable<any>{
    return this.http.get(this.url+'leerusuario/'+id);
  }

  updateUsuario( id, Usuarios: Usuarios): Observable<any>{
    let json = JSON.stringify(Usuarios);
    let params = "json="+json;
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
     
    return this.http.post(this.url+'modificarusuario/'+id, params, {headers: headers});
  }

	makeFileRequest(url: string, params: Array<string>, files: Array<File>){
		return new Promise((resolve, reject)=>{
			var formData: any = new FormData();
			var xhr = new XMLHttpRequest();

			for(var i = 0; i < files.length; i++){
				formData.append('uploads[]', files[i], files[i].name);
			}

			xhr.onreadystatechange = function(){
				if(xhr.readyState == 4){
					if(xhr.status == 200){
						resolve(JSON.parse(xhr.response));
					}else{
						reject(xhr.response);
					}
				}
			};
			xhr.open('POST', url, true);
			xhr.send(formData);
		});
	}
}