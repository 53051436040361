export class Fotografias {
    constructor(
      public idGrafito: number,
      public idFotografia: number,
      public autor: string,
      public fecha: string,
      public camara: string,
      public fichero: string,
      public historico: number,
      public urlImagen: string,
      public usuAlta: number,
      public usuMod: number,
      public created_at: string,
      public updated_at: string,
      public cortesia: string
    ) {}
  }